import React, {useEffect, useState} from 'react';
import {GET_COLLECTION_DATA} from '../../api';
import 'datatables.net';
import 'datatables.net-responsive';
import $ from "jquery";
import cogoToast from "cogo-toast";
import {academia, getSimulator, grade} from "../../request";
import generateId from "../../utils/generateChar";
import AWS from "aws-sdk";
import {capitalize} from "../../utils/capitalize";
import {Link} from "react-router-dom";

const bucket = new AWS.S3({
    accessKeyId: "hcs",
    secretAccessKey: "HCS!2022%",
    endpoint: "https://cloudinary.zstudy.co",
    signatureVersion: 'v4',
    s3ForcePathStyle: true
});

const VirtualLab = () => {

    document.title = "Virtual Lab";

    const token = localStorage.getItem('jwtToken');
    const [gradeId, setGradeId] = useState(0);
    const [formLoading, setFormLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [editForm, setEditForm] = useState(false);
    const [stateReady, setStateReady] = useState(false);
    const [cover_image, set_cover_image] = useState(null);
    let [virtualList, setVirtualList] = useState([]);

    useEffect(() => {
        getSimulator().then((data) => {
            if(!data.error) {
                setVirtualList(data.payload);
                setLoading(false);
            }
        });
    }, []);

    const handleSubmit = async(e) => {
        e.preventDefault();
        setFormLoading(true);

        let name = $('#name').val();
        let section = $('#section').val();

        if(editForm) {
            let raw = {
                id: gradeId,
                name,
                section
            }

            grade("put", raw).then((result) => {
                setFormLoading(false);

                if(!result.error) {
                    cogoToast.success("Data successfully updated.", {position: "top-right", hideAfter: 5});
                    resetForm("modalGrade", "grade_form");
                    reloadTable();
                }
            });

            return false;
        }

        let file = $('#cover_image').prop('files')[0];

        let upload = await S3upload(cover_image);

        let raw = {
            name,
            section,
            cover: {
                name: file.name,
                type: file.type,
                size: file.size.toString(),
                location: upload.Location
            }
        }

        grade("post", raw).then((result) => {
            setFormLoading(false);

            if(!result.error) {
                cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalGrade", "grade_form");
                reloadTable();
            }
        });
    }

    const handleGradeForm = (e) => {
        e.preventDefault();
        setEditForm(false);
        $("#cover_image").prop("required", true);
        $('#grade_form').parsley();
        $("#modalGrade").modal({backdrop: 'static', keyboard: false}, "show");
    }

    const handleFileUpload = (event) => {
        if(event.target.files[0] !== undefined && $('#cover_image').parsley().isValid() !== false) {
            let file = event.target.files[0];
            let image = new Image();
            image.src = window.URL.createObjectURL(file);
            image.onload = function() {
                set_cover_image(file);
            };
        }
    };

    const S3upload = (file) => {
        const params = {
            Bucket: "app.zstudy",
            Key: `uploads/${generateId(50)}`,
            Body: file,
            ContentType: file.type
        };

        return bucket.upload(params, function (err, data) {
            if (err) return console.log({ err });
            return data;
        }).promise();
    }

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const onCloseModal = (id, form) => {
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        $(`#${form}`).parsley().reset();
        $(".modal-backdrop").remove();
    };

    const clearForm = () => {

    }

    const reloadTable = () => {
        $('#td-academia').DataTable().ajax.reload(null, false);
    };

    const handleAddVirtual = () => {

    }

    const handleShowModal = () => {

    }

    const virtualListContent = () => {
        if(virtualList === null || loading) {
            return(
                <div className="content content-fixed content-auth-alt">
                    <div className="container d-flex justify-content-center ht-100p">
                        <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            <p className="mg-t-10">Loading Virtual Lab</p>
                        </div>
                    </div>
                </div>
            )
        } else {
            let renderAd = () => {
                if(virtualList.length < 1) {
                    return(
                        <div className="container d-flex justify-content-center ht-100p mg-t-50">
                            <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                                <div className="container ht-100p">
                                    <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                                        <div className="wd-80p wd-sm-300 mg-b-15 tx-center"><i className="fa-5x fad fa-folder-times"/></div>
                                        <h4 className="tx-20 tx-sm-24 tx-center">Empty</h4>
                                        <p className="tx-color-03 mg-b-40">No Virtual Lab is available or has been removed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                return virtualList.map((result) => {
                    return (
                        <a href={result.file.uri} target="_blank" className="nav-link outline-none tx-14 col-md-4" key={result._id}>
                            <div className="card card-body pd-12 mg-b-8">
                                <img src={result.cover.uri} className="ht-250 wd-100p rounded mg-r-10 mg-b-0 img-fluid" alt=""/>
                                <span className="nav-link outline-none tx-14 text-truncate">{result.name}</span>
                            </div>
                        </a>
                    )
                });
            };

            let renderProceedButton = () => {
                if(stateReady === true) {
                    return(
                        <button className="btn btn-brand-02 btn-block" disabled><span className="spinner-border spinner-border-sm mg-r-10" aria-hidden="true"/><span id="status">Please Wait</span></button>
                    )
                } else {
                    return(
                        <button className="btn btn-success btn-block"><i className="fa fa-check-circle"/> Add</button>
                    );
                }
            };

            return(
                <div className="content-body">
                    <div className="container-fluid pd-x-0">
                        <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                            <div>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                        <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">Virtual Lab</li>
                                    </ol>
                                </nav>
                                <h4 className="mg-b-0 tx-spacing--1">Virtual Lab</h4>
                            </div>
                        </div>

                        <div className="row row-xs">
                            <div className="col-md-6">
                                <a href='https://code.irobot.com/#/'>
                                    <div className="card card-body">
                                        <img src={"assets/img/robotic.jpg"} className="img-fluid" alt="stem" />
                                        <h2 className="text-center mt-3 tx-medium tx-color-02">Robotics</h2>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6">
                                <div className="card card-body">
                                    <img src={"assets/img/stem.jpg"} className="img-fluid" alt="stem" />
                                    <h2 className="text-center mt-3 tx-medium tx-color-02">STEM</h2>
                                </div>
                            </div>
                            {/*{renderAd()}*/}
                        </div>
                    </div>

                    <div className="modal fade" id="modalBanner" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                            <div className="modal-content">
                                <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                    <div className="media align-items-center">
                                        <div className="media-body">
                                            <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close" onClick={clearForm.bind()}>
                                                <span aria-hidden="true">&times;</span>
                                            </a>
                                            <h4 className="tx-18 tx-sm-20 mg-b-2">Add Banner</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                    <form id="banner_form" className="parsley-style-1" onSubmit={handleAddVirtual.bind()}>
                                        <div id="nameWrapper" className="form-group parsley-input">
                                            <label>Name<span className="tx-danger">*</span></label>
                                            <input id="name" type="text" className="form-control"
                                                   placeholder="Enter banner name"
                                                   autoComplete="off"
                                                   data-parsley-class-handler="#nameWrapper" required/>
                                        </div>

                                        <div className="form-group">
                                            <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Upload Images</label>
                                            <input id="image" type="file" className="form-control"
                                                   onChange={handleFileUpload.bind()}
                                                   data-parsley-filemaxmegabytes="1"
                                                   data-parsley-trigger="change"
                                                   data-parsley-filemimetypes="image/jpeg, image/png, image/gif, image/jpg"
                                                   required
                                            />
                                        </div>

                                        {renderProceedButton()}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        virtualListContent()
    )
};

export default VirtualLab;
