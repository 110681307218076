import React, {useEffect, useState} from 'react';
import {GET_COLLECTION_DATA, SEARCH_COLLECTION_URL} from '../../api';
import 'datatables.net';
import 'datatables.net-responsive';
import $ from "jquery";
import cogoToast from "cogo-toast";
import {getStudent, parent, teacher} from "../../request";
import {capitalize} from "../../utils/capitalize";
import {useSelector} from "react-redux";
import moment from "moment/moment";
import {Link} from "react-router-dom";

const Parent = () => {

    document.title = "Parent";

    const auth = useSelector((state) => state.auth.user);

    const token = localStorage.getItem('jwtToken');
    const [parentId, setParentId] = useState(0);
    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);

    useEffect(() => {
        let td_parent = $('#parent-table').DataTable({
            responsive: true,
            language: {
                searchPlaceholder: 'Search name',
                sSearch: '',
                lengthMenu: '_MENU_ items/page',
                processing: '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Parent Report Data</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function (data, callback) {
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "parents",
                        fieldname: "name",
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function (err) {
                        cogoToast.error(err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {
                            position: "top-right",
                            hideAfter: 5
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.name;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.email;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.mobile_number;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY");
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only" id="fake">
                                    <a href="#" class="nav-link edit_parent"><i class="fa fa-pencil-alt"></i> Edit</a><a href="#" class="nav-link delete_parent"><i class="fa fa-trash-alt"></i> Delete</a>
                                </nav>`
                    },
                    "targets": 4
                }
            ]
        });

        td_parent.on('click', '.delete_parent', function(e) {
            e.preventDefault();
            let extract_td = td_parent.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, ${capitalize(extract_td.raw.name)}?`);
            $('#delete-data').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                parent("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        reloadTable();
                        cogoToast.success("Parent successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });

        td_parent.on('click', '.edit_parent', function(e) {
            e.preventDefault();
            let extract_td = td_parent.row($(this).closest('tr')).data();
            setParentId(extract_td.raw._id);
            setEditForm(true);

            $("#parent_name").val(extract_td.raw.name);
            $("#email").val(extract_td.raw.email);
            $("#mobile").val(extract_td.raw.mobile_number);
            $("#address").val(extract_td.raw.address);

            $("#password").prop("required", false);

            $("#modalParent").modal({backdrop: 'static', keyboard: false}, "show");
        });
    }, []);

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const onCloseModal = (id, form) => {
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        $(`#${form}`).parsley().reset();
        $(".modal-backdrop").remove();
    };

    const handleParentSubmit = (e) => {
        e.preventDefault();
        setStateReady(true);

        let raw = {
            name: $('#parent_name').val(),
            email: $('#email').val(),
            mobile_number: $('#mobile').val(),
            password: $('#password').val()
        };

        if(editForm) {
            let raw = {
                id: parentId,
                name: $('#parent_name').val(),
                email: $('#email').val(),
                mobile_number: $('#mobile').val(),
                password: $('#password').val()
            }

            parent("put", raw).then((result) => {
                setStateReady(false);

                if(!result.error) {
                    cogoToast.success("Data successfully updated.", {position: "top-right", hideAfter: 5});
                    resetForm("modalParent", "parent_form");
                    reloadTable();
                }
            });

            return false;
        }

        parent("post", raw).then((result) => {
            setStateReady(false);

            if(!result.error) {
                cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalParent", "parent_form");
                $('#parent-table').DataTable().ajax.reload(null, false);
            }
        });

    };

    const handleModalParent = () => {
        setEditForm(false);
        $('#parent_form').parsley();
        $("#password").prop("required", true);
        $("#modalParent").modal({ backdrop: 'static', focus: false, show: true });
    }

    const reloadTable = () => {
        $('#parent-table').DataTable().ajax.reload(null, false);
    };

    let renderButton = () => {
        if(stateReady === true) {
            return(
                <button disabled className="btn btn-brand-02 btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button>
            )
        } else {
            return editForm ? <button className="btn btn-brand-02 btn-block">Update Account</button> : <button className="btn btn-brand-02 btn-block">Create Account</button>
        }
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><a href="#">Dashboard</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Parent</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Parent</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5 outline-none" onClick={handleModalParent.bind()}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-sm">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Parent List</h6>
                                <div className="d-flex tx-18">
                                    <Link to="#" onClick={() => $('#parent-table').DataTable().ajax.reload(null, false)} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></Link>
                                </div>
                            </div>
                            <div className="card-body table-responsive">
                                <table id="parent-table" className="table table-hover" style={{width: "100%"}}>
                                    <thead className="thead-light">
                                    <tr>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Email</th>
                                        <th className="wd-20p">Mobile Number</th>
                                        <th className="wd-20p">Date Created</th>
                                        <th className="wd-20p">Actions</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalParent" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <a href="#" role="button" className="close pos-absolute t-15 r-15"
                               data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </a>
                            <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={onCloseModal.bind(null, "modalParent", "parent_form")} aria-label="Close"><span aria-hidden="true">×</span></a>
                                <h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Parent" : "Add Parent"}</h4>
                                <p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} user to have access to the ZStudy parent app</p></div>
                        </div>
                        <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                            <form id="parent_form" className="parsley-style-1" data-parsley-validate="true" noValidate
                                  onSubmit={handleParentSubmit.bind()}>
                                <div id="nameWrapper" className="form-group parsley-input">
                                    <label>Full Name<span className="tx-danger">*</span></label>
                                    <input id="parent_name" type="text" className="form-control"
                                           placeholder="Enter full name"
                                           autoComplete="none"
                                           data-parsley-class-handler="#nameWrapper" required/>
                                </div>

                                <div id="emailWrapper" className="form-group parsley-input">
                                    <label>Email Address<span className="tx-danger">*</span></label>
                                    <input id="email" type="email" className="form-control"
                                           placeholder="Enter valid email address"
                                           autoComplete="none"
                                           data-parsley-class-handler="#emailWrapper" required/>
                                </div>

                                <div id="mobileWrapper" className="form-group parsley-input">
                                    <label>Mobile Number<span className="tx-danger">*</span></label>
                                    <input id="mobile" type="number" className="form-control"
                                           placeholder="Enter mobile number"
                                           autoComplete="none"
                                           data-parsley-class-handler="#mobileWrapper" required/>
                                </div>

                                <div id="passwordWrapper" className="form-group parsley-input">
                                    <label>Password<span className="tx-danger">*</span></label>
                                    <input id="password" type="password" className="form-control"
                                           placeholder="Enter new passowrd"
                                           autoComplete="none"
                                           data-parsley-class-handler="#passwordWrapper"/>
                                </div>

                                {renderButton()}
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content tx-14">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="mg-b-0 data-message">Empty</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="delete-data" className="btn btn-success btn-block">Yes, Delete
                            </button>
                            <button type="button" className="btn btn-danger btn-block mt-0"
                                    data-dismiss="modal">Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Parent;
