import React, {useCallback, useEffect, useRef} from "react";
import { GoogleMap, Polygon, OverlayView, useLoadScript } from "@react-google-maps/api";
import mapStyle from "../../data/map.json";

const ViewGMap = ({ polygons }) => {
    const mapRef = useRef(null);

    const onLoad = useCallback(map => {
        mapRef.current = map;
        if (polygons && polygons.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            polygons.forEach(polygon => {
                polygon.coordinates.forEach(coord => {
                    bounds.extend(coord);
                });
            });
            map.fitBounds(bounds);
        }
    }, [polygons]);

    useEffect(() => {
        if (mapRef.current && polygons && polygons.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            polygons.forEach(polygon => {
                polygon.coordinates.forEach(coord => {
                    bounds.extend(coord);
                });
            });
            mapRef.current.fitBounds(bounds);
        }
    }, [polygons]);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
        libraries: ["drawing"]
    });

    const calculateCenter = (coordinates) => {
        const bounds = new window.google.maps.LatLngBounds();
        coordinates.forEach(coord => bounds.extend(coord));
        return bounds.getCenter().toJSON();
    };

    return (
        <>
            {!isLoaded ? (
                <h6 style={{ marginTop: 40 }}>Loading Map...</h6>
            ) : (
                <div>
                    <GoogleMap
                        mapContainerClassName="map-container"
                        onLoad={onLoad}
                        zoom={10}
                        options={{ styles: mapStyle }}
                    >
                        {polygons && polygons.map((polygon, index) => (
                            <React.Fragment key={index}>
                                <Polygon
                                    paths={polygon.coordinates}
                                    options={{
                                        fillColor: polygon.fillColor,
                                        fillOpacity: 0.35,
                                        strokeColor: "#FF0000",
                                        strokeOpacity: 0.35,
                                        strokeWeight: 2.5,
                                        editable: false,
                                        draggable: false
                                    }}
                                />
                                <OverlayView
                                    position={calculateCenter(polygon.coordinates)}
                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                >
                                    <div style={{
                                        display: 'inline-block',
                                        backgroundColor: 'white',
                                        padding: '5px 10px', // Add padding to ensure text is properly wrapped
                                        borderRadius: '8px',
                                        boxShadow: '0 1px 4px rgba(0, 0, 0, 0.3)',
                                        textAlign: 'center', // Ensure text is centered within the div
                                        whiteSpace: 'nowrap' // Prevent text from breaking into multiple lines
                                    }}>
                                        <h6 style={{
                                            margin: 0,
                                            padding: 0, // Remove any default padding or margin from the heading
                                            fontSize: '14px' // Adjust font size if necessary
                                        }}>
                                            {polygon.name}
                                        </h6>
                                        <div className="d-flex align-items-center">
                                            <div className="avatar wd-20 ht-20"><img src={polygon.logo} className="rounded-circle" alt="logo"/></div>
                                            <span className="tx-color-0 tx-14 pd-x-5">{polygon.collector}</span>
                                        </div>
                                    </div>
                                </OverlayView>
                            </React.Fragment>
                        ))}
                    </GoogleMap>
                </div>
            )}
        </>
    );
};

export default ViewGMap;