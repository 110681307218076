import React, { useCallback, useEffect, useRef, useState } from "react";
import { GoogleMap, Polyline, Marker, InfoWindow, useLoadScript } from "@react-google-maps/api";
import mapStyle from "../../data/map.json";

const TrackMap = ({ coordinates, pinpoints }) => {
    const mapRef = useRef(null);
    const [selectedPoint, setSelectedPoint] = useState(null); // State for the selected point

    const onLoad = useCallback(map => {
        mapRef.current = map;
        if (coordinates.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            coordinates.forEach(coord => bounds.extend(coord));
            map.fitBounds(bounds); // Adjusts the zoom level based on coordinates
        }
    }, [coordinates]);

    useEffect(() => {
        if (mapRef.current && coordinates.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            coordinates.forEach(coord => bounds.extend(coord));
            mapRef.current.fitBounds(bounds); // Adjust zoom to fit all points
        }
    }, [coordinates]);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
        libraries: ["drawing"],
    });

    return (
        <>
            {!isLoaded ? (
                <h6 style={{ marginTop: 40 }}>Loading Map...</h6>
            ) : (
                <div>
                    <GoogleMap
                        mapContainerClassName="map-container"
                        onLoad={onLoad}
                        zoom={10}
                        options={{ styles: mapStyle }}
                    >
                        {coordinates.length > 1 && (
                            <>
                                {/* Polyline for the path */}
                                <Polyline
                                    path={coordinates}
                                    options={{
                                        strokeColor: "#F44A53",
                                        strokeOpacity: 1.0,
                                        strokeWeight: 5,
                                    }}
                                />

                                {/* Starting point marker */}
                                <Marker
                                    position={coordinates[0]}
                                    icon={{
                                        url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                                        scaledSize: new window.google.maps.Size(40, 40)
                                    }}
                                />

                                {/* Ending point marker */}
                                <Marker
                                    position={coordinates[coordinates.length - 1]}
                                    icon={{
                                        url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                                        scaledSize: new window.google.maps.Size(40, 40)
                                    }}
                                />
                            </>
                        )}

                        {/* Multiple Pinpoints with InfoWindows */}
                        {pinpoints?.map((point, index) => (
                            <Marker
                                key={point.check._id}
                                position={{
                                    lat: point.check.location.coordinates[0],
                                    lng: point.check.location.coordinates[1]
                                }}
                                icon={{
                                    url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                                    scaledSize: new window.google.maps.Size(40, 40),
                                }}
                                onClick={() => setSelectedPoint(point)}
                            />
                        ))}

                        {/* Display InfoWindow when a marker is clicked */}
                        {selectedPoint && (
                            <InfoWindow
                                position={{
                                    lat: selectedPoint.check.location.coordinates[0],
                                    lng: selectedPoint.check.location.coordinates[1]
                                }}
                                onCloseClick={() => setSelectedPoint(null)}
                            >
                                <a href={`#/student/${selectedPoint.student._id}`} target="_blank" className="text-center link-02">
                                    <div className="text-center">
                                        <img className="rounded-50 mb-3" src={selectedPoint.student.photo.uri} height="80" width="80" alt="student-photo"/>
                                    </div>
                                    <h6>Student Information</h6>
                                    <p>Student ID: {selectedPoint.student.id_number}</p>
                                    <p>Student Name: {selectedPoint.student.firstName} {selectedPoint.student.lastName}</p>
                                    <p>Check-in Time: {new Date(selectedPoint.check.date.iso).toLocaleString()}</p>
                                </a>
                            </InfoWindow>
                        )}
                    </GoogleMap>
                </div>
            )}
        </>
    );
};

export default TrackMap;
