import React from 'react';
import PrivateRoute from '../../components/common/PrivateRoute';
import {Switch, Route} from 'react-router-dom';
import PageError from "../../components/layout/PageError";
import Dashboard from '../../pages/authorized/Dashboard';
import ReportRoute from '../../pages/authorized/Route';
import Grade from "../../pages/authorized/Grade";
import Academic from "../../pages/authorized/Academic";
import StudentData from "../../pages/authorized/ViewStudent"
import ViewAcademic from "../../pages/authorized/ViewAcademic";
import VirtualLab from "../../pages/authorized/VirtualLab";
import Teacher from "../../pages/authorized/Teacher";
import Parent from "../../pages/authorized/Parent";
import Patron from "../../pages/authorized/Patron";
import Vehicle from "../../pages/authorized/Vehicle";
import Zone from "../../pages/authorized/Zone";
import Driver from "../../pages/authorized/Driver";

const Private = () => {
    return (
        <div>
            <Switch>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute exact path="/academic" component={Academic} />
                <PrivateRoute exact path="/teacher" component={Teacher} />
                <PrivateRoute exact path="/bus/patron" component={Patron} />
                <PrivateRoute exact path="/bus/vehicle" component={Vehicle} />
                <PrivateRoute exact path="/bus/route" component={ReportRoute} />
                <PrivateRoute exact path="/bus/zone" component={Zone} />
                <PrivateRoute exact path="/bus/driver" component={Driver} />
                <PrivateRoute exact path="/grade" component={Grade} />
                <PrivateRoute exact path="/parent" component={Parent} />
                <PrivateRoute exact path="/virtual" component={VirtualLab} />
                <PrivateRoute exact path="/view-academic/:academic" component={ViewAcademic} />
                <PrivateRoute exact path="/student/:id" component={StudentData} />
                <Route component={PageError} />
            </Switch>
        </div>
    )
};

export default Private;
